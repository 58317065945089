<template>
  <div>
    <!-- 页头搜索 -->
    <div class="input">
      <div class="input-font">
        <div class="fontdiv">支付方式代码</div>
        <el-input placeholder="请输入支付方式代码" v-model="searchForm.wayCode" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">支付方式名称</div>
        <el-input placeholder="请输入支付方式名称" v-model="searchForm.wayName" clearable size="medium"></el-input>
      </div>
      <el-button class="search-button button-height" type="primary" icon="el-icon-search" :loading="false"
        @click="getTableData()">搜索
      </el-button>
      <el-button class="button-height" plain icon="el-icon-refresh" @click="resetSearch()">重置</el-button>
    </div>

    <!-- 页头按钮 -->
    <div class="button">
      <el-row>
        <el-button size="medium" type="success" plain icon="el-icon-download" :disabled="false" @click="add()">添加
        </el-button>
        <el-button size="medium" type="danger" plain icon="el-icon-download" :disabled="false"
          @click="deleteList()">批量删除
        </el-button>
      </el-row>
    </div>

    <!-- 列表渲染 -->
    <div class="table">
      <el-table :data="tableData" ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange">
        <el-table-column type="selection" min-width="60"></el-table-column>
        <el-table-column prop="wayName" label="支付方式名称" min-width="80"></el-table-column>
        <el-table-column prop="wayCode" label="支付方式代码" min-width="100">
          <template #default="scope">
            <el-tag>{{ scope.row.wayCode }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="120"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="120"></el-table-column>
        <!-- 浮动侧边栏 -->
        <el-table-column label="操作" min-width="130" fixed="right" align="center">
          <template #default="scope">
            <el-link class="marginRight" @click="editRow(scope.row)" type="primary" :underline="false">编辑</el-link>
            <el-link @click="deleteRow(scope.row.wayCode)" type="danger" :underline="false">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <Pagination :total="total" :page="pageNumber" :size="pageSize" @getPage="getPage($event)"
      @getSize="getSize($event)">
    </Pagination>

    <!-- 添加 -->
    <el-drawer title="添加" :visible.sync="addFormVisible" direction="rtl" :before-close="handleClose" size="35%">
      <div class="drawer-content">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="支付方式名称" prop="wayName">
            <el-input v-model="addForm.wayName" placeholder="请输入支付方式名称"></el-input>
          </el-form-item>
          <el-form-item label="支付方式代码" prop="wayCode">
            <el-input v-model="addForm.wayCode" placeholder="请输入支付方式代码"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="addFormCancel">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm">
            重置
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确认
          </a-button>
        </div>
      </div>
    </el-drawer>

    <!-- 修改  -->
    <el-drawer title="修改" :visible.sync="updateFormVisible" direction="rtl" :before-close="handleClose" size="35%">
      <div class="drawer-content">
        <el-form :model="updateForm" :rules="rules" ref="updateForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="支付方式名称" prop="wayName">
            <el-input v-model="updateForm.wayName" placeholder="请输入支付方式名称"></el-input>
          </el-form-item>
          <el-form-item label="支付方式代码" prop="wayCode">
            <el-input v-model="updateForm.wayCode" placeholder="请输入支付方式代码"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="updateFormCancel">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm">
            重置
          </a-button>
          <a-button type="primary" @click="editSubmit">
            保存
          </a-button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import {
  getPayWayInfoData,
  deletesPayWayInfo,
  updatePayWayInfo,
  addPayWayInfo,
} from "@/api/payManagement/payWayInfo";
import Pagination from "@/components/Pagination";

export default {
  name: "payWayInfo",
  components: { Pagination },
  data() {
    return {
      //页头搜索数据
      searchForm: {},
      //保存多选框选中的行数据
      selectedRows: [],
      //渲染列表数据
      tableData: [],
      //总条数
      total: 0,
      //当前页数
      pageNumber: 1,
      //当前每页条数
      pageSize: 5,
      //修改 是否展示
      updateFormVisible: false,
      //修改表单数据
      updateForm: {},
      //添加 是否展示
      addFormVisible: false,
      //添加表单数据
      addForm: {},
      //添加表单数据规则
      rules: {
        wayCode: [
          { required: true, message: '请输入支付方式代码', trigger: 'blur' }
        ],
        wayName: [
          { required: true, message: '请输入支付方式名称', trigger: 'blur' }
        ],
      },
    };
  },

  mounted() {
    this.getTableData();
  },

  methods: {
    //分页查询
    async getTableData() {
      const data = { ...this.searchForm, pageNum: this.pageNumber, pageSize: this.pageSize };
      const res = await getPayWayInfoData(data);
      console.log(res)
      this.tableData = res.data.rows
      this.total = parseInt(res.data.total)
    },

    // 页头重置
    resetSearch() {
      this.searchForm = {}
    },

    //通过选中的多行数据存入数组
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      console.log(this.selectedRows)
    },

    //表单重置（添加/修改）
    resetForm() {
      this.addForm = {}
      this.updateForm = {}
    },

    //批量删除按钮
    async deleteList() {
      this.$confirm('此操作将永久删除选中商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = this.selectedRows.map((item) => {
          return item.wayCode
        })
        console.log(data)
        const res = await deletesPayWayInfo(data);
        console.log(res)
        this.$message.success("删除成功");
        this.getTableData();
      }).catch((e) => {
        if (e == 'cancel') {
          this.$message.info("已取消删除");
        }
      });
    },

    //添加按钮
    add() {
      this.addFormVisible = true
    },

    //添加抽屉取消按钮
    addFormCancel() {
      this.addFormVisible = false
      this.addForm = {}
    },

    //添加抽屉提交按钮
    async addSubmit() {
      const res = await addPayWayInfo(this.addForm)
      this.$message.success("添加成功");
      this.getTableData()
      this.addFormVisible = false
    },

    // 修改状态
    async changeStatus(row) {
      console.log(row.status)
      var text = "操作成功"
      if (row.status === 0) {
        text = "启用成功"
      } else if (row.status == 1) {
        text = "禁用成功"
      }
      const data = {
        "id": row.id,
        "status": row.status
      }
      const res = await updateUserStatus(data)
      if (res.code === 200) {
        this.$message.success(text);
      }
    },

    //修改
    editRow(row) {
      this.updateForm = row
      this.updateFormVisible = true
    },

    //修改抽屉取消按钮
    updateFormCancel() {
      this.updateFormVisible = false
      this.updateForm = {}
    },

    //修改抽屉保存按钮
    async editSubmit() {
      const res = await updatePayWayInfo(this.updateForm)
      this.$message.success("修改成功");
      this.updateFormVisible = false
    },

    // 删除单条
    deleteRow(id) {
      this.$confirm('此操作将永久删除该商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = [id]
        const res = await deletesPayWayInfo(data);
        this.getTableData();
        this.$message.success("删除成功");
      }).catch(() => {
        this.$message.info("已取消删除");
      });
    },

    //抽屉关闭回调
    handleClose(done) {
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => { });
      done()
      this.addForm = {}
      this.updateForm = {}
      this.argumentForm = {}
    },

    //获取当前页数
    getPage(value) {
      this.pageNumber = value;
      this.getTableData();
    },

    //获取每页多少条
    getSize(value) {
      this.pageSize = value;
      this.getTableData();
    },
  }
}
</script>

<style scoped lang="less">
/* 搜索框样式 */
.input {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;

  .input-font {
    width: 400px;
    display: flex;
    margin-bottom: 10px;

    .fontdiv {
      color: #999;
      font-weight: 600;
      font-size: 15px;
      width: 150px;
      line-height: 36px;
      text-align: center;
    }

    .select-width {
      width: 400px;
    }
  }

  .button-height {
    height: 36px;
    margin-bottom: 10px;
    margin-left: 15px;
  }

}

/* 表格 */
.table {
  margin-top: 15px;

  .marginRight {
    margin-right: 20px;
  }

  :deep .el-table .el-table__header-wrapper .el-table__header .el-table__cell {
    font-size: 14px;
    color: #999;
    font-weight: 600px;
    text-align: center;
    background-color: #f2f2f2;
  }

  :deep .el-table th.el-table__cell.is-leaf {
    background-color: #f2f2f2 !important;
  }

  :deep .el-table .el-table__body-wrapper .el-table__body .el-table__row .el-table__cell .cell {
    text-overflow: clip;
    text-align: center;
  }
}

/* 抽屉内容 */
.drawer-content {
  padding: 0 30px 70px 20px;

  .drawer-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
  }

  .marginRight {
    margin-right: 8px;
  }
}
</style>